.relatedproducts{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 90vh;
    background-image: url('../Assets/background_img3.png');
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 75px;
    margin-bottom: 75px;
}
.relatedproducts h1{
    color: #454a54;
    font-family: Poppins, sans-serif;
    font-size: 2rem; 
    font-weight: 300; 
    letter-spacing: 0.05em; 
    text-transform: none; 
    margin-top: 30px;
    line-height: 1.4;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.relatedproducts hr{
    width: 40%;
    height: 2px;
    border: none;
    background: #3498db;
    border-radius: 5px;
    margin: 10px auto;
}
.relatedproducts-item{
    margin-top: 50px;
    display: flex;
    gap: 20px;
}
.relatedproducts-slider {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-left: 50px;
}
  
  .slick-arrow {
    font-size: 24px;
    color: #333;
    background-color: #2C5F7D;
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .slick-arrow:hover {
    color: white;
    background-color: #2C5F7D;
  }
  
  .slick-next {
    z-index: 1;
    margin-right: 90px;
  }
  
  .slick-prev {
    z-index: 1;
    margin-left: 25px;
  }
  
@media(max-width:1280px){
    .relatedproducts{
        height: 70vh;
    }
    .relatedproducts h1{
        font-size: 40px;
    }
    .relatedproducts hr{
        width: 160px;
        height: 4px;
    }
    .relatedproducts-item{
        gap: 20px;
        margin-top: 30px;
    }
}

@media(max-width:1024px){
    .relatedproducts{
        height: 60vh;
    }
    .relatedproducts h1{
        font-size: 30px;
    }
    .relatedproducts hr{
        width: 120px;
        height: 3px;
    }
    .relatedproducts-item{
        gap: 15px;
        margin-top: 20px;
    }
}

@media(max-width:800px){
    .relatedproducts{
        height: 50vh;
        gap: 6px;
    }
    .relatedproducts h1{
        font-size: 20px;
    }
    .relatedproducts hr{
        width: 100px;
    }
    .relatedproducts-item{
        gap: 5px;
    }
}

@media(max-width:500px){
    .relatedproducts{
        height: 90vh;
        padding-left: 60px;
    }
    .relatedproducts-item{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .slick-arrow{
        display: none;
    }
    .slick-next {
        display: none;
    }
}
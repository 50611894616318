.new-collections {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 100px;
    background-image: url('../Assets/background_img3.png');
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.new-collections h1 {
    color: #454a54;
    font-family: Poppins, sans-serif;
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-transform: none;
    margin-top: 30px;
    line-height: 1.4;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.new-collections hr {
    width: 40%;
    height: 1px;
    border: none;
    background: #3498db;
    border-radius: 5px;
    margin: 10px auto;
}

.collections {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 50px;
    gap: 20px;
    padding-left: 30px;
    padding-right: 30px;
}

.collections img {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.collections img:hover {
    transform: scale(1.1);
}

@media(max-width:1280px){
    .new-collections h1{
        font-size: 40px;
    }
    .collections{
        gap: 20px;
        margin-top: 30px;
    }
    .new-collections {
        padding: 15px;
        margin-bottom: 0px;
    }
}

@media(max-width:1024px){
    .new-collections h1{
        font-size: 30px;
    }
    .collections{
        margin-top: 20px;
    }
    .new-collections {
        padding: 15px;
        margin-bottom: 0px;
    }
}

@media(max-width:800px){
    .new-collections h1{
        font-size: 20px;
    }
    .new-collections {
        padding: 10px;
        margin-bottom: 0px;
        width: 90%;
    }
}

@media(max-width:500px){
    .collections{
        display: grid;
        grid-template-columns: 1fr;
    }
    .new-collections {
        padding: 10px;
        margin-bottom: 0px;
        width: 90%;
    }
    .collections img{
        width: 250px;
        margin-left: -45px;
    }
}

@media(max-width:300px) {
    .collections{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .new-collections {
        padding: 10px;
        margin-bottom: 0px;
        width: 90%;
    }
}

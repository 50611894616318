.shopcategory-banner{
    display: block;
    margin: 30px auto;
    width: 82%;
    border-radius: 20px;
}
.shopcategory-products{
    width: 85%;
    background-image: url('../../Components/Assets/background_img3.png');
    background-repeat: repeat;
    background-size: contain;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 170px;
    margin-left: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 50px;
    column-gap: 10px;
    align-items: center;
}
.shopcategory-loadmore{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    width: 233px;
    height: 69px;
    border-radius: 75px;
    background: #ededed;
    color: #787878;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}
@media (max-width: 1280px) {
    .shopcategory-banner {
        margin: 20px auto;
        width: 90%;
    }
    .shopcategory-products {
        width: 90%;
        padding: 20px;
        margin: 20px auto 100px;
        column-gap: 10px;
    }

    .shopcategory-loadmore {
        margin: 60px auto;
        width: 200px;
        height: 60px;
        font-size: 16px;
    }
}

@media (max-width: 800px) {
    .shopcategory-products {
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }
    .shopcategory-banner{
        display: block;
        margin: 30px auto;
        width: 82%;
        border-radius: 20px;
    }
}

.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    gap: 50px;
}
.footer-logo{
    display: flex;
    align-items: center;
    margin-top: 0px;
    gap: 20px;
}
.footer-logo p{
    color: #383838;
    font-size: 46px;
    font-weight: 700;
}
.footer-links{
    background-color: #f2f2f2;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #2C5F7D;
    font-size: 20px;
    font-weight: 500;
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
}
.footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-bottom: 30px;
    color: #1a1a1a;
    font-size: 20px;
}
.footer-copyright hr{
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 3px;
    background: #c7c7c7;
}
.east-logo-text {
    color: #9d0208;
}
.west-logo-text {
    color: darkgreen;
}
.footer-logo-second{
    height: 200px;
}
@media(max-width:800px) {
    .footer{
        gap: 20px;
    }
    .footer-logo{
        gap: 10px;
        align-items: end;
    }
    .footer-logo img{
        width: 40px;
    }
    .footer-logo p{
        font-size: 25px;
    }
    .footer-links{
        font-size: 14px;
        gap: 10px;
    }
    .footer-copyright{
        font-size: 13px;
    }
    .footer-logo-second{
        height: 50px;
    }
}

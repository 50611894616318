.hero {
    height: 100vh;
    background: linear-gradient(180deg, #CBE6F6, #e1ffea22 60%);
    display: flex;
    overflow: hidden;
}

.hero-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-left: 180px;
    line-height: 1.4;
}

.hero-right {
    margin-top: 80px;
    color: #090909;
    font-size: 26px;
    font-weight: 600;
    flex: 1;
    width: 70%;
    height: 70%;
}

.hero-left p {
    color: #171717;
    font-size: 50px;
    font-weight: 200;
    opacity: 0.9;
    position: relative;
    transition: transform 0.3s ease-in-out;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-left p:hover {
    animation: rearrange 1s forwards;
}

@keyframes rearrange {
    0% {
        transform: perspective(1000px) rotateY(0deg);
    }
    50% {
        transform: perspective(1000px) rotateY(180deg);
    }
    100% {
        transform: perspective(1000px) rotateY(0deg);
    }
}

.hero-latest-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 310px;
    height: 70px;
    border-radius: 10px;
    margin-top: 30px;
    background: #2C5F7D;
    color: white;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
}

.hero-right {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.hero-right img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


@media(max-width:1280px){
    .hero-left{
        padding-left: 100px;
    }
    .hero-left h2{
        font-size: 22px;
    }
    .hero-left p{
        font-size: 60px;
    }
    .hero-hand-icon img{
        width: 80px;
    }
    .hero-latest-button{
        gap: 10px;
        width: 250px;
        height: 60px;
        margin-top: 20px;
        font-size: 18px;
    }
    .hero-right {
        width: 50%;
        height: 50%;
    }
}

@media(max-width:1024px){
    .hero-left{
        padding-left: 80px;
    }
    .hero-left h2{
        font-size: 20px;
    }
    .hero-left p{
        font-size: 50px;
    }
    .hero-hand-icon img{
        width: 65px;
    }
    .hero-latest-button{
        width: 220px;
        height: 50px;
        font-size: 16px;
    }
    .hero-right {
        width: 50%;
        height: 50%;
    }
}

@media(max-width:800px){
    .hero-left{
        padding-left: 30px;
    }
    .hero-left h2{
        font-size: 16px;
    }
    .hero-left p{
        font-size: 40px;
    }
    .hero-hand-icon img{
        width: 50px;
    }
    .hero-latest-button{
        width: 175px;
        height: 40px;
        font-size: 13px;
    }
    .hero-right {
        width: 50%;
        height: 50%;
    }
}

@media(max-width:500px){
    .hero{
        flex-direction: column;
    }
    .hero-right{
        display: none;
    }
    .hero-left h2{
        font-size: 18px;
    }
    .hero-left p{
        font-size: 50px;
    }
    .hero-hand-icon img{
        width: 55px;
    }
    .hero-latest-button{
        width: 200px;
        height: 45px;
        font-size: 14px;
    }
}
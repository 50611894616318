.item{
    width: 280px;
}

.item img{
    width: 280px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    object-fit: cover;
    height: 350px;
}

.item p{
    margin: 6px 0px;
}

.item-prices{
    display: flex;
    gap: 20px;
}

.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}

.item-price-old{
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover img {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);
}

@media(max-width:1280px){
    .item{
        width: 220px;
        font-size: 14px;
    }
    .item img{
        width: 220px;
    }
    .item-price-old{
        font-size: 14px;
    }
}

@media(max-width:1024px){
    .item{
        width: 170px;
        font-size: 13px;
    }
    .item img{
        width: 170px;
    }
    .item-price-old{
        font-size: 13px;
    }
}

@media(max-width:800px){
    .item{
        width: 120px;
        font-size: 12px;
    }
    .item img{
        width: 120px;
    }
    .item-price-old{
        font-size: 12px;
    }
}

@media(max-width:500px){
    .item{
        width: 160px;
    }
    .item img{
        width: 160px;
    }
}
.newsletter{
    width: 85%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 150px;
}

.newsletter img{
    width: 85%;
    border-radius: 20px;
    transition: transform 0.3s ease-in-out;
}

.newsletter:hover img {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);
}

@media(max-width:1280px) {
    .newsletter{
        margin-top: 30px;
        margin-bottom: 100px;
    }
}

@media(max-width:1024px) {
    .newsletter{
        margin-top: 30px;
        margin-bottom: 70px;
    }
}

@media(max-width:800px) {
    .newsletter{
        margin-top: 20px;
        margin-bottom: 50px;
    }
}

@media(max-width:500px) {
    .newsletter{
        margin-top: 25px;
        margin-bottom: 15px;
        width: 100%;
        height: 100%;
    }
}